/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useReducer, memo, useContext } from "react";
import reducer from "./AuthReducer";
import { REFRESH, SIGNOUT } from "./AuthActions";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { useMutation } from "react-apollo";
import { LOGOUT } from "../graphql/mutattions/auth";
import { COOKIE_NAME_AUTH_TOKEN, COOKIE_SAVED_DATA } from "../graphql/config";
import IdleTimer from "react-idle-timer";

const getUserData = () => {
  const s = Cookies.getJSON(COOKIE_SAVED_DATA);
  // console.log("Data");
  // console.log(s);
  return s;
};

const setToken = (token, expires) =>
  Cookies.set(COOKIE_NAME_AUTH_TOKEN, token, { expires });

const TIMEOUT = 60 * 1000 * 30;

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export default memo(({ children }) => {
  const [logout] = useMutation(LOGOUT);
  const [state, dispatch] = useReducer(
    reducer,
    { user: null, token: null },
    getUserData
  );

  const authenticate = async (values, referrer = "/admin/") => {
    setToken(values.access_token, 0.5);
    console.log(values);
    await Cookies.set(
      COOKIE_SAVED_DATA,
      {
        user: {
          ...values.user,
        },
        token: values.access_token,
      },
      { expires: 0.5 }
    );
    dispatch({
      type: REFRESH,
      payload: {
        user: { ...values.user },
        token: values.access_token,
      },
    });
    window.location.href = referrer;
  };

  const signOut = (e) => {
    logout()
      .then((res) => {
        Cookies.remove(COOKIE_SAVED_DATA);
        Cookies.remove(COOKIE_NAME_AUTH_TOKEN);
        dispatch({ type: SIGNOUT });
      })
      .catch((err) => {});
  };

  const warn = () => {
    if (state && state.user) {
      swal({
        icon: "info",
        title: "Inactivity",
        text: "you will be logged out in a minute",
        timer: 1000 * 60 * 0.5,
        buttons: [
          "Logout",
          {
            text: "Keep My Session active",
          },
        ],
      }).then((input) => {
        if (!input) {
          return signOut();
        }
      });
    }
    return;
  };

  return (
    <IdleTimer timeout={TIMEOUT} onIdle={warn}>
      <AuthContext.Provider
        value={{
          state,
          user: state?.user,
          dispatch,
          authenticate,
          signOut,
        }}
      >
        {children}
      </AuthContext.Provider>
    </IdleTimer>
  );
});
