/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Suspense, createContext, useContext } from "react";
import { Switch, Route, Link, useLocation, Redirect } from "react-router-dom";
import routes from "../routes";
import Zoom from "react-reveal/Reveal";
import { AuthContext } from "../context/AuthContext";
import NotifcIcon from "./NotifcIcon";
import ConnectionDetector from "../components/ConnectionDetector";
import SideBar from "./SideBar";

export const AdminContext = createContext();

const getRoutes = (routes) => {
  return routes.map((el, key) => {
    const Component = el.component;
    const MainComponent = () => (
      <Zoom effect="fadeInUp">
        <Component />
      </Zoom>
    );
    return (
      <Private
        key={key}
        path={el.layout + el.path}
        exact
        component={MainComponent}
      />
    );
  });
};

export default React.memo(() => {
  const { state, signOut } = useContext(AuthContext);
  const { pathname } = useLocation();

  return !state?.user ? (
    <Redirect to={{ pathname: "/auth", state: pathname }} />
  ) : (
    <AdminContext.Provider>
      <div id="layout-wrapper">
        <header id="page-topbar">
          <div className="navbar-header nav-irisk">
            <div className="d-flex nav-irisk">
              <div className="navbar-brand-box">
                <Link to="/admin/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img
                      src={require("../assets/logo-sm.png")}
                      alt=""
                      height="22"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src={require("../assets/irisk-logo-big.png")}
                      alt=""
                      width="100"
                      height="60"
                    />
                  </span>
                </Link>

                <Link to="/admin/" className="logo logo-light">
                  <span className="logo-sm">
                    <img
                      src={require("../assets/logo-sm.png")}
                      alt=""
                      height="40"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src={require("../assets/irisk-logo-big.png")}
                      alt=""
                      width="200"
                      height="60"
                    />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>

            <div className="d-flex nav-irisk">
              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>

              <NotifcIcon state={state} />

              <div className="dropdown d-inline-block dropright">
                <button
                  type="button"
                  className="btn header-item waves-effect"
                  id="page-header-user-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <span className="rounded-circle header-profile-user d-flex justify-content-center align-items-center  rounded-circle bg-soft-primary text-primary font-size-12">
                    {state.user?.employee?.emp_abbrv}
                  </span>
                  <span className="d-none d-xl-inline-block ml-1">
                    {state.user?.employee?.employee_first_name}
                  </span>
                  <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-left">
                  <Link
                    to={{ pathname: "/admin/profile" }}
                    className="dropdown-item"
                  >
                    <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                    Profile
                  </Link>

                  <div className="dropdown-divider"></div>
                  <button
                    onClick={signOut}
                    className="dropdown-item text-danger"
                  >
                    <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        <SideBar />

        <div className="main-content">
          {/* Main Content */}
          <Suspense fallback={<div> loading </div>}>
            <ConnectionDetector />
            <Switch>{getRoutes(routes)}</Switch>
          </Suspense>

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <script>document.write(new Date().getFullYear());</script>©
                  iRisk re-insurance
                </div>
                <div className="col-sm-6 d-flex justify-content-end">
                  <img src={require("../assets/ssl-sm.png")} alt="ssl" />
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </AdminContext.Provider>
  );
});

const Private = React.memo((props) => {
  const { state } = useContext(AuthContext);
  const { pathname } = useLocation();
  return !state.user ? (
    <Redirect to={{ pathname: "/auth", state: { pathname } }} />
  ) : (
    <Route {...props} />
  );
});
