import { lazy } from "react";

const ViewEndorsement = lazy(() => import("./pages/ViewEndorsement"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const CreateSlip = lazy(() => import("./pages/CreateSlip"));
const ViewOffer = lazy(() => import("./pages/CreateSlip/ViewOffer"));
const CreateClosing = lazy(() => import("./pages/CreateClosing"));
const ReInsurers = lazy(() => import("./pages/ReInsurers"));
const SetupBusiness = lazy(() => import("./pages/SetUpBusiness"));
const Insurers = lazy(() => import("./pages/Insurers"));
const InsurersDetails = lazy(() => import("./pages/Insurers/InsurersDetail"));
const Reports = lazy(() => import("./pages/Reports"));
const Employees = lazy(() => import("./pages/Employees"));
const Claims = lazy(() => import("./pages/Claims"));
const ReinsurersDetails = lazy(() =>
  import("./pages/ReInsurers/ReinsurerDetail")
);
const Notifications = lazy(() => import("./pages/Notifications"));

const Profile = lazy(() => import("./pages/profile"));

export default [
  {
    path: "/",
    layout: "/admin",
    component: Dashboard,
  },
  {
    path: "/notifications",
    layout: "/admin",
    component: Notifications,
  },
  {
    path: "/profile",
    layout: "/admin",
    component: Profile,
  },
  {
    path: "/create-slip",
    layout: "/admin",
    component: CreateSlip,
  },
  {
    path: "/re-insurers",
    layout: "/admin",
    component: ReInsurers,
  },
  {
    path: "/insurers",
    layout: "/admin",
    component: Insurers,
  },
  {
    path: "/employees",
    layout: "/admin",
    component: Employees,
  },
  {
    path: "/reports",
    layout: "/admin",
    component: Reports,
  },
  {
    path: "/claims",
    layout: "/admin",
    component: Claims,
  },
  {
    path: "/insurers-details",
    layout: "/admin",
    component: InsurersDetails,
  },
  {
    path: "/re-insurers-detail",
    layout: "/admin",
    component: ReinsurersDetails,
  },
  {
    path: "/setup-business",
    layout: "/admin",
    component: SetupBusiness,
  },
  {
    path: "/create-closing",
    layout: "/admin",
    component: CreateClosing,
  },
  {
    path: "/view-offer",
    layout: "/admin",
    component: ViewOffer,
  },
  {
    path: "/view-endorsement",
    layout: "/admin",
    component: ViewEndorsement,
  },
  {
    path: "/",
    layout: "/auth",
    component: Login,
  },
];
