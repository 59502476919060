import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { DOMAIN, COOKIE_NAME_AUTH_TOKEN, PROTOCOL } from "./config";
import Cookies from "js-cookie";

const getToken = () => {
  const token = Cookies.get(COOKIE_NAME_AUTH_TOKEN);
  return token;
};

export const BASE_URL_LOCAL = `${PROTOCOL}${DOMAIN}`;

const httpLink = createUploadLink({
  uri: BASE_URL_LOCAL + "/graphql",
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {

      console.log(message, locations)
      return message
    });
  }

  if (networkError) {
    // swal({
    //   closeOnClickOutside: false,
    //   closeOnEsc: false,
    //   title: "Whoops!!",
    //   text: "Something went wrong. Come back later",
    //   icon: "error",
    //   buttons: [],
    // });
  }
});

const link = ApolloLink.from([errorLink, httpLink]);

const client = new ApolloClient({
  link,
  cache,
});

export default client;
