export default [
  {
    link: "/admin/",
    name: "Dashboard",
    icon: "bx bx-home-circle",
  },
  {
    link: "/admin/create-slip",
    name: "Create Slip",
    icon: "bx bxs-file-plus",
  },
  {
    link: "/admin/create-closing",
    name: "Create Closing",
    icon: "bx bx-lock-alt",
  },
  {
    link: "/admin/re-insurers",
    name: "Reinsurers",
    icon: "bx bx-group",
  },
  {
    link: "/admin/setup-business",
    name: "Setup Business",
    icon: "bx bx-group",
  },
  {
    link: "/admin/insurers",
    name: "Insurers",
    icon: "bx bx-group",
  },
  {
    link: "/admin/employees",
    name: "Employees",
    icon: "bx bx-home-circle",
  },
  {
    link: "/admin/reports",
    name: "Reports",
    icon: "bx bx-home-circle",
  },
];

const dashboard = [
  {
    link: "/admin/",
    name: "Dashboard",
    icon: "bx bx-home-circle",
  },
];

const offers = [
  {
    link: "/admin/create-slip",
    name: "Create Slip",
    icon: "bx bxs-file-plus",
  },
  {
    link: "/admin/create-closing",
    name: "Create Closing",
    icon: "bx bx-lock-alt",
  },
];
const clients = [
  {
    link: "/admin/insurers",
    name: "Insurers",
    icon: "bx bx-group",
  },
  {
    link: "/admin/re-insurers",
    name: "Reinsurers",
    icon: "bx bx-group",
  },
];
const others = [
  {
    link: "/admin/setup-business",
    name: "Setup Business",
    icon: "bx bx-group",
  },
  {
    link: "/admin/claims",
    name: "Claims",
    icon: "bx bx-receipt",
  },
  {
    link: "/admin/employees",
    name: "Employees",
    icon: "bx bx-id-card",
  },
  {
    link: "/admin/reports",
    name: "Reports",
    icon: "bx bx-box",
  },
  // {
  //   link: "/admin/view-endorsement",
  //   name: "View Endorsement",
  //   icon: "bx bx-box",
  // },
];

export const deleteAccessRoles = [
  "General Manager",
  "CEO",
  "Senior Broking Officer",
  "System Administrator",
];
export const editAccessRoles = [
  "General Manager",
  "Senior Broking Officer",
  "Finance Executive",
  "CEO",
  "System Administrator",
];

const create_Access = [
  "General Manager",
  "Senior Broking Officer",
  // 'Finance Executive',
  "CEO",
  "System Administrator",
];

const create_insurer_access = [
  "System Administrator",
  "Senior Broking Officer",
  "General Manager",
];

const delete_insurer_access = [
  "System Administrator",
  "Senior Broking Officer",
  "General Manager",
];

const edit_insurer_access = [
  "System Administrator",
  "Senior Broking Officer",
  "General Manager",
];

//Broker
export const edit_broker_access = [
  "System Administrator",
  "Senior Broking Officer",
  "General Manager",
];
export const delete_broker_access = [
  "System Administrator",
  "Senior Broking Officer",
  "General Manager",
];
export const create_broker_access = [
  "System Administrator",
  "Senior Broking Officer",
  "General Manager",
];

const create_reinsurer_access = [
  "System Administrator",
  "Senior Broking Officer",
  "General Manager",
];
const delete_reinsurer_access = [
  "System Administrator",
  "Senior Broking Officer",
  "General Manager",
];
const edit_reinsurer_access = [
  "System Administrator",
  "Senior Broking Officer",
  "General Manager",
];

const Offers_Access = [
  "CEO",
  "General Manager",
  "Senior Broking Officer",
  "System Administrator",
];

const treaty_Access = [
  "CEO",
  "Senior Broking Officer",
  "System Administrator",
  "General Manager",
];
const clients_Access = [
  "CEO",
  "General Manager",
  "Senior Broking Officer",
  "Finance Executive",
  "System Administrator",
];
const others_Access = [
  "CEO",
  "General Manager",
  "Senior Broking Officer",
  "Finance Executive",
  "System Administrator",
];

const insurer_offer_access = ["Finance Executive"];

export const technicalTeam = [
  "CEO",
  "General Manager",
  "Senior Broking Officer",
  "System Administrator",
];

export const financeTeam = ["Finance Executive"];

// export { clients, others, offers, dashboard };

export {
  clients,
  others,
  offers,
  dashboard,
  Offers_Access,
  clients_Access,
  others_Access,
  create_Access,
  create_insurer_access,
  delete_insurer_access,
  edit_insurer_access,
  insurer_offer_access,
  create_reinsurer_access,
  delete_reinsurer_access,
  edit_reinsurer_access,
  treaty_Access,
};
