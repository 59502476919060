export const PROTOCOL =
  process.env.NODE_ENV === "development" ? "https://" : "https://";
export const DOMAIN =
  process.env.NODE_ENV === "development"
    ? "manage.iriskre.com"
    : "manage.iriskre.com";
export const COOKIE_SAVED_DATA = "irisK_auth";
export const COOKIE_NAME_AUTH_TOKEN = "irisk_token";
export const PUSHER_KEY = "b1140f551046f9ab20e1";
export const PUSHER_CLUSTER = "eu";
export const PUSHER_CHANNEL = "a2VrcmUtcHJpdmF0ZS1jaGFubmVsirisk";
export const PUSHER_CHANNEL_EVENT = "a2VrcmUtcHJpdmF0ZS1jaGFubmVsirisk-event";
