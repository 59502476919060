import React from "react";

const ConnectionDetector = ({children}) => {
  const [online, setOnline] = React.useState(true);

  React.useEffect(() => {
    setOnline(navigator.onLine);
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);
  }, []);

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        })
          .then(() => {
            setOnline(true);
            clearInterval(webPing);
          })
          .catch(() => setOnline(false));
      }, 2000);
    } else {
      setOnline(false);
    }
  };


  return !online ? (
    <div className="internet-error">No Internet connection</div>
  ) : null;
};

export default ConnectionDetector;
